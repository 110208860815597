<template>
  <section class="app-main">
    <div class="container is-fluid is-marginless app-content">
      <div class="columns head is-vcentered">
        <div class="column is-offset-3 is-6 has-text-centered">
          <h3 class="title is-size-3 has-text-weight-normal">Echanger un service</h3>
        </div>
      </div>
      <div class="animated m-b-30">
        <div class="tile is-ancestor">
          <div class="tile is-parent is-success">
            <div class="tile is-child box">
              <h4 v-if="swapId === 'new'" class="title is-size-4">Proposer un échange</h4>
              <h4 v-else class="title is-size-4">Modifier ma proposition d'échange</h4>
              <div class="line" >Si je n'ai pas trouvé de créneau qui me convient, je propose mon service à l'échange.</div>
                <form>
                  <b-field label="Je sélectionne mon service à échanger :">
                    <div v-if="filteredServices.length">
                      <b-radio v-for="service in filteredServices" :key="service.id"  v-model="selectedService" :native-value="service" type="is-success">
                        {{ formatDate(service.dateBegin) }}
                      </b-radio>
                    </div>
                    <div v-else-if="this.swapId !== 'new' && this.selectedService" class="static">
                      {{ formatDate(this.selectedService) }}
                    </div>
                    <div v-else class="static">
                      Pas de services programmés ou à venir.
                    </div>
                  </b-field>
                  <b-field label="J'indique comment me contacter :">
                    <div class="block">
                      <div>L’ajout de vos coordonnées est à votre convenance. Mais sans indications, les coopérateurs n'auront aucun autre élément pour vous contacter.</div>
                      <b-input v-model="contact" maxlength="100" type="textarea"></b-input>
                    </div>
                  </b-field>
                  <h5 class="title is-size-6">J'indique aux autres coopérateurs mes préférences/disponibilités (pour indication) :</h5>
                  <GridWeekSelect :preferences="preferences" @updatePreferences="updatePreferences" />
              </form>
              <div class="danger" v-if="(!selectedService || !contact)">Veuillez sélectionner un service à échanger et remplir des indications de contact afin de publier votre proposition.</div>
              <div class="foot">
                    <b-button v-if="swapId === 'new'" type="is-primary" native-type="submit" :disabled="(!selectedService || !contact)" @click="submit" :class="(!selectedService || !contact) ? 'locked' : ''">Publier mon service</b-button>
                    <b-button v-else type="is-primary" native-type="submit" :disabled="(!selectedService || !contact)" @click="edit(swapId)" :class="(!selectedService || !contact) ? 'locked' : ''">Enregistrer</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
  </section>
</template>

<script>
import shiftServices from "@/services/shiftServices";
import swapServices from "@/services/swapServices";
import date from "@/utils/formatDate";
import GridWeekSelect from '../GridWeekSelect.vue';

export default {
  name: "Propose",
  components: {
    GridWeekSelect
  },
  mixins: [date],
  data: function () {
    return {
      mesinfos: "",
      services: [],
      selectedService: undefined,
      contact: '',
      preferences: [],
      swapId: this.$route.params.id
    };
  },
  mounted() {
    this.mesinfos = this.$store.getters.getUser;
    this.init();
  },
  computed: {
    filteredServices() {
      if (this.services.length > 0) {
        return this.services.slice().reverse().filter(service => {
          return (
            Date.now() <= Date.parse(service.dateBegin)
          );
        });
      }
      return []
    }
  },
  methods: {
    init: function () {
      if (this.swapId !== undefined && this.swapId !== "new") {
        swapServices
          .fetchSwap(this.swapId)
          .then(response => {
            const swap = response.data
            this.selectedService = swap.shiftDate
            this.contact = swap.contact
            this.preferences = swap.preferedShifts
          })
        .catch(() => { this.errorAPI = true })
      } else {
        shiftServices
        .fetchMyNextShifts(this.mesinfos.id)
        .then(
          response =>
            {this.services = response.data.shiftRegistrations.reverse(); }
        )
        .catch(() => {
          this.errorAPI = true;
        });
      }
    },
    submit() {
      const daysOrder = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
      const orderedPreferences = this.preferences.sort((a, b) => {
        const dayA = daysOrder.find(day => a.startsWith(day));
        const dayB = daysOrder.find(day => b.startsWith(day));
        return daysOrder.indexOf(dayA) - daysOrder.indexOf(dayB);
      })
      swapServices.publishSwap(this.selectedService.id, this.selectedService.dateBegin, this.contact, orderedPreferences)
      this.$router.push('/exchange')
    },
    edit(swapId) {
      const daysOrder = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
      const orderedPreferences = this.preferences.sort((a, b) => {
        const dayA = daysOrder.find(day => a.startsWith(day));
        const dayB = daysOrder.find(day => b.startsWith(day));
        return daysOrder.indexOf(dayA) - daysOrder.indexOf(dayB);
      })
      swapServices.editSwap(swapId, this.selectedService.id, this.selectedService.dateBegin, this.contact, orderedPreferences)
      this.$router.push('/exchange')
    },
    updatePreferences(newPreference) {
      this.preferences = newPreference;
    },
  },
};
</script>

<style lang="css" scoped>
h5 {
  margin: 10px 0px;
}
.line {
  margin-bottom: 15px;
}
.static {
  font-style: italic;
  margin-left: 15px;
}
.foot {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.block {
  display: flex;
  flex-direction: column;
}
.danger {
  color: red;
  font-style: italic;
}
</style>
